import Image from 'next/image';
import Link from 'next/link';

import routes from '@/routes';

import Button from '../utils/Button';

import styles from './PageFooter.module.scss';

const PageFooter: React.FC = () => {
  return (
    <footer className={styles.pageFooter}>
      <div className="container">
        <div className={styles.pageFooterLayout}>
          <div className={styles.pageFooterAbout}>
            <div className={styles.pageFooterLogo}>
              <Image src="/images/logo.png" alt="Logo" fill />
            </div>
            <p>
              Własna Działka - Twój kawałek raju na ziemi! Odkryj nieograniczone możliwości i
              zrealizuj marzenia o idealnym miejscu dla Ciebie i Twojej rodziny.
            </p>
          </div>

          <div className={styles.pageFooterNav}>
            <ul>
              <li>
                <Link href={routes.projects()}>Inwestycje</Link>
              </li>
              <li>
                <Link href={routes.contact()}>Kontakt</Link>
              </li>
              <li>
                <Link href={routes.terms()}>Regulamin</Link>
              </li>
              <li>
                <Link href={routes.privacyPolicy()}>Polityka prywatności</Link>
              </li>
            </ul>
          </div>

          <div className={styles.pageFooterAddress}>
            <h4>Kontakt</h4>

            <address>
              Korkowa 24a
              <br />
              04-502 Warszawa
            </address>

            <div className={styles.pageFooterContact}>
              <Button href="/kontakt">Wyślij wiadomość »</Button>
            </div>
          </div>
        </div>

        <div className={styles.pageFooterCopyrights}>
          <span>
            <strong>wlasnadzialka.eu</strong> - Wszystkie prawa zastrzeżone
          </span>
          <div className={styles.pageFooterLogos}>
            <a
              href="https://wizytowka.rzetelnafirma.pl/WZCWEGEV"
              target="_blank"
              rel="nofollow"
              className={styles.pageFooterAboutImage1}
            >
              <Image src="/images/logo-rzetelnafirma.png" fill alt="Baner Rzetelna Firma" />
            </a>
            <div className={styles.pageFooterAboutImage2}>
              <Image src="/images/logo-topfirma.png" alt="Logo Top Firma" fill />
            </div>
            <div className={styles.pageFooterAboutImage3}>
              <Image
                src="/images/logo-firma-godna-zaufania.jpeg"
                alt="Logo Firma Godna Zaufania"
                fill
              />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default PageFooter;
