import { useEffect, useState } from 'react';

import Image from 'next/image';
import Link from 'next/link';
import { Router } from 'next/router';

import classNames from 'classnames';

import routes from '@/routes';

import styles from './PageHeader.module.scss';

const PageHeader: React.FC = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const resetOpen: () => void = () => setOpen(false);

    Router.events.on('routeChangeStart', resetOpen);

    return () => {
      Router.events.off('routeChangeStart', resetOpen);
    };
  }, []);

  return (
    <div className={classNames(styles.pageHeader, { [styles.pageHeader_open]: open })}>
      <div className="container">
        <div className={styles.pageHeaderLayout}>
          <Link href="/" className={styles.pageHeaderLogo}>
            <Image src="/images/logo.png" alt="Logo" fill />
          </Link>

          <button
            type="button"
            className={styles.mobileMenu}
            onClick={() => setOpen(!open)}
            aria-label="Menu"
          />

          <div className={styles.pageHeaderNav}>
            <ul className={styles.pageHeaderMenu}>
              <li>
                <Link href="/">Home</Link>
              </li>
              <li>
                <Link href={routes.projects()}>Inwestycje</Link>
              </li>
              <li>
                <Link href={routes.articles()}>Artykuły</Link>
              </li>
              <li>
                <Link href={routes.contact()}>Kontakt</Link>
              </li>
            </ul>
            <div className={styles.pageHeaderSocialIcons}>
              {process.env.NEXT_PUBLIC_LINKED_IN_URL ? (
                <Link
                  href={process.env.NEXT_PUBLIC_LINKED_IN_URL}
                  target="_blank"
                  title="Profil LinkedIn"
                >
                  <Image src="/images/icon-social-linkedin.svg" height={32} width={32} alt="" />
                </Link>
              ) : null}
              {process.env.NEXT_PUBLIC_FACEBOOK_URL ? (
                <Link
                  href={process.env.NEXT_PUBLIC_FACEBOOK_URL}
                  target="_blank"
                  title="Profil Facebook"
                >
                  <Image src="/images/icon-social-fb.svg" height={32} width={32} alt="" />
                </Link>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PageHeader;
