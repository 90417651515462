import Link from 'next/link';

import styles from './Button.module.scss';

interface Props {
  href: string;
  children: React.ReactNode;
}

const Button: React.FC<Props> = ({ href, children }) => {
  return (
    <Link className={styles.button} href={href}>
      {children}
    </Link>
  );
};
export default Button;
