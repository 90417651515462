import { useState, useEffect } from 'react';

import type { AppProps } from 'next/app';
import Head from 'next/head';
import { Router, useRouter } from 'next/router';
import Script from 'next/script';

import ReactModal from 'react-modal';
import { ToastContainer } from 'react-toastify';

import CallModal from '@/components/contact/CallModal';
import Layout from '@/components/layout/Layout';
import Loader from '@/components/layout/Loader';

import 'react-toastify/dist/ReactToastify.css';
import '@/styles/globals.scss';
import gtag from '../../gtag';

const googleAnalyticsId = process.env.NEXT_PUBLIC_GA4_TRACKING_ID;

ReactModal.setAppElement('#__next');

export default function App({ Component, pageProps }: AppProps) {
  const [loading, setLoading] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const startLoading: () => void = () => setLoading(true);
    const finishLoading: () => void = () => setLoading(false);

    Router.events.on('routeChangeStart', startLoading);
    Router.events.on('routeChangeComplete', finishLoading);

    return () => {
      Router.events.off('routeChangeStart', startLoading);
      Router.events.off('routeChangeComplete', finishLoading);
    };
  }, []);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      gtag.pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Layout>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="icon" href="/favicon.png" />
        </Head>
        <Component {...pageProps} />

        <CallModal />
        {loading && <Loader fullSize />}
        <ToastContainer
          hideProgressBar={false}
          position="top-right"
          autoClose={5000}
          closeOnClick
          pauseOnHover
          draggable
          theme="colored"
        />
      </Layout>
      {googleAnalyticsId !== undefined ? (
        <>
          <Script
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`}
          />
          <Script
            strategy="afterInteractive"
            id="google-analytics-tracking"
            dangerouslySetInnerHTML={{
              __html: `
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', '${googleAnalyticsId}', {
  page_path: window.location.pathname,
});
`,
            }}
          />
        </>
      ) : null}
    </>
  );
}
