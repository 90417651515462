import qs from 'qs';

import type ArticleType from './types/ArticleType';
import type ProjectType from './types/ProjectType';

const routes = {
  articles: (page = 1) =>
    `/artykuly${qs.stringify(page > 1 ? { page } : {}, { addQueryPrefix: true })}`,
  article: (article: ArticleType) => `/artykuly/${article.slug}`,
  projects: () => '/inwestycje',
  project: (project: ProjectType) => `/inwestycje/${project.slug}`,
  contact: () => '/kontakt',
  terms: () => '/regulamin',
  privacyPolicy: () => '/polityka-prywatnosci',
};

export default routes;
