import ApiError from './ApiError';

interface OptionsType {
  method: string;
  headers: Headers;
  body?: FormData | string;
}

const apiHost = process.env.NEXT_PUBLIC_API_HOST;

const apiUrl = (relativeUrl: string) => {
  return `${apiHost}${`/api/${relativeUrl}`.replace(/\/\//g, '/')}`;
};

const rawRequest = async (
  url: string,
  params?: Record<string, unknown> | FormData,
  method = 'GET',
): Promise<Response> => {
  const headers: HeadersInit = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  const options: OptionsType = {
    method,
    headers: new Headers(headers),
  };

  if (typeof FormData !== 'undefined' && params instanceof FormData) {
    options.headers.delete('Content-Type');
    options.body = params;
  } else if (params != null) {
    options.body = JSON.stringify(params);
  }
  return fetch(apiUrl(url), options);
};

export const apiRequest = async <ResponseType>(
  url: string,
  params?: Record<string, unknown> | FormData,
  method = 'GET',
): Promise<{ response: ResponseType; headers: Headers }> => {
  const response = await rawRequest(url, params, method);
  if (response.ok) {
    const jsonResponse = await response.json();
    return { response: jsonResponse, headers: response.headers };
  }
  const statusCode = response.status;
  const errorResponse = await response.json();

  return Promise.reject(new ApiError({ statusCode, ...errorResponse }));
};

const getRequest = async <ResponseType>(url: string): Promise<ResponseType> => {
  return (await apiRequest<ResponseType>(url)).response;
};

const postRequest = async <ResponseType>(url: string, params = {}): Promise<ResponseType> => {
  return (await apiRequest<ResponseType>(url, params, 'POST')).response;
};

const putRequest = async <ResponseType>(url: string, params = {}): Promise<ResponseType> => {
  return (await apiRequest<ResponseType>(url, params, 'PUT')).response;
};

const deleteRequest = async <ResponseType>(url: string, params = {}): Promise<ResponseType> => {
  return (await apiRequest<ResponseType>(url, params, 'DELETE')).response;
};

export { rawRequest, getRequest, postRequest, putRequest, deleteRequest };
