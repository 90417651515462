import { useState } from 'react';

import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import ReactModal from 'react-modal';
import { toast } from 'react-toastify';

import { readexPro } from '@/fonts';
import askForContactService, { AskForContactParams } from '@/services/askForContactService';
import ApiError from '@/utils/ApiError';

import Loader from '../layout/Loader';

import styles from './CallModal.module.scss';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed' as const,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 99,
  },
  content: {
    zIndex: 100,
  },
};

interface CallFormParams {
  phone_number: string;
}

const CallModal: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm<CallFormParams>();

  const onSubmit = (data: CallFormParams) => {
    setLoading(true);
    askForContactService
      .askForContact(data)
      .then(() => {
        toast.success('Twoja wiadomość została wysłana');
        setOpen(false);
        reset({ phone_number: '' });
      })
      .catch((e: ApiError) => {
        if (e.statusCode === 422) {
          Object.entries(e.errors || {}).forEach(([name, message]) => {
            setError(name as keyof AskForContactParams, {
              message: Array.isArray(message) ? message.join(', ') : message,
            });
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <button
        className={styles.callModalButton}
        type="button"
        onClick={() => setOpen(true)}
        aria-label="Kontakt telefoniczny"
      ></button>
      <ReactModal
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        closeTimeoutMS={500}
        style={customStyles}
        className={classNames(readexPro.className, styles.callModal)}
      >
        {loading ? <Loader className={styles.loader} /> : null}
        <button
          className={styles.callModalClose}
          type="button"
          onClick={() => setOpen(false)}
        ></button>
        <h2>Kontakt telefoniczny</h2>
        <h3>Zainteresowała Cię nasza oferta? Zostaw swój numer, oddzwonimy!</h3>

        <form onSubmit={handleSubmit(onSubmit)} className={styles.callModalForm}>
          <div className={styles.callModalFormGroup}>
            <input
              type="tel"
              {...register('phone_number', { required: 'musisz podać numer telefonu' })}
              placeholder="telefon"
              className={styles.callModalFormInput}
            />
            <button type="submit">
              <img src="/images/icon-phone.svg" alt="" />
              Zamawiam rozmowę
            </button>
          </div>
          {errors.phone_number ? (
            <div className={styles.callModalFormError}>{errors.phone_number.message}</div>
          ) : null}
        </form>

        <p>
          Przesłanie formularza i podanie danych osobowych jest dobrowolne, lecz niezbędne do
          realizacji zgłoszenia. Informujemy, że Państwa dane osobowe będą przetwarzane w celu
          realizacji tego zgłoszenia oraz w celu marketingu produktów i usług Administratorów
          Państwa danych Duoinvest.pl sp. z o.o., ul. Korkowa 24a, 04-502 Warszawa, KRS 0000843142,
          REGON 386135637, NIP 9522206461
        </p>
      </ReactModal>
    </>
  );
};
export default CallModal;
