const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA4_TRACKING_ID;

interface WindowType extends Window {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  gtag: any;
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
const pageview = (url: string) => {
  if (GA_TRACKING_ID !== undefined) {
    (window as unknown as WindowType).gtag('config', GA_TRACKING_ID, {
      page_path: url,
    });
  } else {
    console.error('Missing GA4 tracking id');
  }
};

// // https://developers.google.com/analytics/devguides/collection/gtagjs/events
// export const event = ({ action, category, label, value }) => {
//   window.gtag('event', action, {
//     event_category: category,
//     event_label: label,
//     value: value,
//   });
// };

const gtag = {
  pageview,
};

export default gtag;
