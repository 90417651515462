import Image from 'next/image';

import styles from './BottomImage.module.scss';

const BottomImage: React.FC = () => {
  return (
    <div className={styles.bottomImage}>
      <Image src="/images/bottom-image.jpg" alt="" fill />
    </div>
  );
};
export default BottomImage;
