import { useEffect, useState } from 'react';

import Link from 'next/link';

import { getCookie, setCookies } from 'cookies-next';

import routes from '@/routes';

import styles from './CookiesLayer.module.scss';

const COOKIE_KEY = 'wlasnadzialka-cookie-layer';

const CookiesLayer: React.FC = () => {
  const [showCookieLayer, setShowCookieLayer] = useState(false);

  useEffect(() => {
    setShowCookieLayer(getCookie(COOKIE_KEY) !== '1');
  }, []);

  const handleAccept = () => {
    setCookies(COOKIE_KEY, '1', {
      maxAge: 60 * 60 * 24 * 365,
    });
    setShowCookieLayer(false);
  };

  return (
    <div
      className={[styles.cookiesLayer, showCookieLayer && styles.cookiesLayer_visible].join(' ')}
    >
      <div className="container">
        <div className={styles.cookiesLayerWrapper}>
          <div className={styles.cookiesLayerText}>
            Serwis używa plików cookie, które są niezbędne do komfortowego korzystania z portalu.
            Możesz w dowolnej chwili zmodyfikować ustawienia cookie w swojej przeglądarce. Więcej
            informacji znajdziesz w naszej{' '}
            <Link href={routes.privacyPolicy()}>Polityce Prywatności</Link>.
          </div>
          <div className={styles.cookiesLayerButtons}>
            <button className={['btn', styles.btn].join(' ')} onClick={handleAccept}>
              Zamknij
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiesLayer;
