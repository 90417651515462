import type ErrorsType from '../types/ErrorsType';

class ApiError extends Error {
  name = 'ApiError';

  message: string;

  errors: { [key: string]: Array<string> } | undefined;

  statusCode: number;

  constructor(body: ErrorsType) {
    super();
    this.errors = body.errors;
    this.statusCode = body.statusCode;
    this.message = `API error occurred (${this.statusCode})`;
  }
}

export default ApiError;
