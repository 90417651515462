import { readexPro } from '@/fonts';

import CookiesLayer from './CookiesLayer';
import PageFooter from './PageFooter';
import PageHeader from './PageHeader';
import BottomImage from '../home/BottomImage';

interface Props {
  children: React.ReactNode;
}

const Layout: React.FC<Props> = ({ children }) => {
  return (
    <div className={readexPro.className}>
      <PageHeader />
      <main>
        {children}
        <BottomImage />
      </main>

      <PageFooter />
      <CookiesLayer />
    </div>
  );
};

export default Layout;
