import classnames from 'classnames';

import styles from './Loader.module.scss';

interface Props {
  fullSize?: boolean;
  className?: string;
}

const Loader: React.FC<Props> = ({ fullSize = false, className }) => {
  return (
    <div className={classnames(styles.loader, { [styles.loaderFull]: fullSize }, className)}>
      <div className={styles.spinner}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loader;
