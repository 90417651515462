import { postRequest } from '@/utils/ApiClient';

export interface AskForContactParams {
  phone_number: string;
}

const askForContact = async (data: AskForContactParams): Promise<{ status: boolean }> => {
  return postRequest('ask-for-contact', { ask_for_contact: data });
};

const askForContactService = {
  askForContact,
};

export default askForContactService;
